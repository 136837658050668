.form {
    &--login .button--login {
        padding: 1em;
        width: 100%;
    }
}

.form-section {
    position: relative;

    + .form-section {
        margin-top: $gutter;
    }

    .label {
        display: block;
        line-height: 1;
        padding: 1rem 0 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        transition: all .15s ease-in-out;
    }

    .toggle-password-field {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

// Overwrite the default autofill puke-yellow background on
// autofilled form elements. Little hacky, but it works.
input:-webkit-autofill { box-shadow: 0 0 0 1000px $white inset; }

.input {
    // background-color: lighten($color-primary, 50%);
    border: 0;
    border-bottom: 2px solid lighten($color-primary, 50%);
    color: $color-primary;
    display: block;
    font-size: $font-size-regular;
    line-height: 1.2;
    outline: none;
    padding: 1rem 0;
    transition: background-color .2s ease-in-out;
    width: 100%;

    &:hover { border-bottom-color: lighten($color-primary, 40%); }

    &:focus {
        border-bottom-color: $color-primary;
        // box-shadow: 0 0 10px $marine;
        // color: $color-primary-invert;
    }

    &:focus + .label,
    + .label--input-not-empty {
        font-size: $font-size-small;
        padding: 0;
        top: -1rem;
    }
}

.form-group {
    + .form-group { margin-top: $gutter; }
}
