@import 'utils/reset',
    'utils/functions/z-index',
    'utils/functions/remove-unit',
    'utils/functions/unit-conversion',
    'utils/functions/grid',

    'utils/helpers/clearfix',

    'utils/mixins/breakpoints',
    'utils/mixins/grid',

    'base/config',
    'base/base',
    'base/typography',

    'utils/utilities',

    'components/section/section',

    'components/buttons',
    'components/brand',
    'components/pill',
    'components/forms',
    'components/notifications',
    'components/svg/logo',

    'components/navigation/navigation-primary',
    'components/navigation/navigation-sticky';

.login {
    background-color: $color-primary;

    .page-content {
        display: flex;
        height: 100%;
        padding: ($gutter / 2);

        @include breakpoint(m) {
            padding: $gutter;
        }
    }

    .wrapper {
        margin: auto;
        max-width: map-get($breakpoints, m);
        width: 100%;
    }
}
